import { ZIndexContext, useZIndexContext } from '@local/web-design-system/dist/hooks/useZIndex';
import { Legend } from '@local/webviz/dist/components/Legend/Legend';
import {
    DEFAULT_POSITION,
    DEFAULT_POSITION_FACTOR,
} from '@local/webviz/dist/components/Legend/Legend.constants';
import type {
    LegendPanelPositionProps,
    LegendPanelStateProps,
} from '@local/webviz/dist/components/Legend/Legend.types';
import { DEFAULT_COLORMAP_NAME } from '@local/webviz/dist/components/Properties/Colormaps/Colormap.constants';
import { useAttributeProperties } from '@local/webviz/dist/context/hooks/useAttributeProperties';
import { useBaseXyz } from '@local/webviz/dist/context/hooks/useBaseXyz';
import type { ColorMappingData } from '@local/webviz/dist/types';
import { parseSuffixUid } from '@local/webviz/dist/utilities/uuidGenerators';
import { MappingClass } from '@local/webviz/dist/xyz';
import Grid from '@mui/material/Grid';
import isEqual from 'lodash-es/isEqual';
import { useCallback, useState } from 'react';

import { useSelectedObjectsAttributes } from 'src/hooks/useSelectedObjectsAttributes';
import { getColormapById } from 'src/store/colormap/selectors';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { getActiveColormapByObjectId } from 'src/store/visualization/selectors';
import { updateObjectLegendShown } from 'src/store/visualization/visualizationSlice';
import { useLegends } from 'src/visualization/context/hooks/useLegends';
import { getObjectIdFromViewId } from 'src/visualization/context/snapshots/generateSnapshot';
import { tokenProvider } from 'src/visualization/Plot/Plot';

import { useStyles } from './LegendPanel.styles';

export function LegendPanel() {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const zIndexContext = useZIndexContext();
    const legends = useLegends();

    const [defaultPanelPosition, setDefaultPanelPosition] = useState<LegendPanelPositionProps>({});

    const defaultPosition = (idx: number) => {
        const currentPosition = defaultPanelPosition[idx];
        if (currentPosition && !isEqual(currentPosition, DEFAULT_POSITION)) {
            return currentPosition;
        }
        return {
            ...DEFAULT_POSITION,
            x: idx * DEFAULT_POSITION_FACTOR,
        };
    };

    const createPositionChangeHandler = useCallback(
        (idx: number) => (x: number, y: number) => {
            setDefaultPanelPosition((current) => ({ ...current, [idx]: { x, y } }));
        },
        [setDefaultPanelPosition],
    );

    return (
        <Grid container className={classes.overlayDialog}>
            <ZIndexContext.Provider value={zIndexContext}>
                {Object.values(legends)
                    .filter(({ enabled }) => enabled)
                    .map(({ artifactId, viewId, arrayType }, i) => (
                        <LegendWithState
                            key={artifactId}
                            artifactId={artifactId}
                            viewId={viewId}
                            defaultPosition={defaultPosition(i)}
                            onClose={() => {
                                dispatch(updateObjectLegendShown({ objectIds: [artifactId] }));
                            }}
                            onPositionChange={createPositionChangeHandler(i)}
                            arrayType={arrayType}
                        />
                    ))}
            </ZIndexContext.Provider>
        </Grid>
    );
}

function LegendWithState({
    artifactId,
    viewId,
    defaultPosition,
    onClose,
    onPositionChange,
    arrayType,
}: LegendPanelStateProps) {
    const { getEntityState } = useBaseXyz();
    const activeColormapId = useAppSelector(getActiveColormapByObjectId(artifactId));
    const colormap = useAppSelector(getColormapById(parseSuffixUid(activeColormapId)));

    const objectId = getObjectIdFromViewId(viewId);
    const { attributes } = useSelectedObjectsAttributes([objectId]);
    const {
        attributeProperties: { selectedAttributeName },
    } = useAttributeProperties({ attributes, selectedViewId: viewId });

    const mappingEntityState = getEntityState(activeColormapId) as ColorMappingData;

    /* eslint-disable-next-line no-underscore-dangle */
    const mappingEntityClass = mappingEntityState?.__class__;

    if (!mappingEntityClass) {
        return null;
    }

    let legendName = colormap?.name;

    if (!legendName) {
        if (mappingEntityClass === MappingClass.Continuous) {
            legendName = DEFAULT_COLORMAP_NAME;
        } else {
            legendName = selectedAttributeName;
        }
    }

    mappingEntityState.name = legendName;

    return (
        <Legend
            viewId={viewId}
            mapping={mappingEntityState}
            defaultPosition={defaultPosition}
            onClose={onClose}
            onPositionChange={onPositionChange}
            arrayType={arrayType}
            tokenProvider={tokenProvider}
        />
    );
}
